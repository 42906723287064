const TARGET_ENV = process.env.REACT_APP_TARGET_ENV || "development"

const IS_DEVELOPMENT = TARGET_ENV === "development"
const IS_PRODUCTION = TARGET_ENV === "production"
const IS_STAGING = TARGET_ENV === "staging"
const IS_TEST = TARGET_ENV === "test"
const IS_PRODUCTION_LIKE = IS_PRODUCTION || IS_STAGING

export const env = {
  IS_DEVELOPMENT,
  IS_PRODUCTION,
  IS_PRODUCTION_LIKE,
  IS_STAGING,
  IS_TEST,
}
