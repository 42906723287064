import CompeonAPI from "@compeon-os/api-client"

import { deepCamelTransform } from "utils/deepCamelTransform"

const client = new CompeonAPI({
  baseURL: process.env.REACT_APP_API_URL,
  skipAuthentication: true,
  skipLogout: true,
  authConfig: {},
  transformResponse: [deepCamelTransform],
})

export default client
