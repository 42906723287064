import { keyframes } from "styled-components"

export const STAGGER_BY_MS = 250

export const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

export const swoopIn = keyframes`
  from {
    opacity: 0;
    transform:
      rotate3d(1, 0, 0, 8deg)
      scale(0.94)
      translateY(-40px)
    ;
  }

  to {
    opacity: 1;
  }
`
export const swoopOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    transform:
      rotate3d(1, 0, 0, 8deg)
      scale(0.94)
      translateY(-40px)
    ;
    opacity: 0;
  }
`

export const rotateIn = keyframes`
  0% {
    opacity: 0;
    transform: rotate(90deg);
  }
  100% {
    opacity: 1;
    transform: rotate(0);
  }
`

export const flyIn = keyframes`
  0% {
    transform: translate(14px, -14px);
    filter: blur(2px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    filter: blur(0px);
    opacity: 1;
  }
`

export const flyInRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(320px);
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
`

export const flyOutRight = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(320px);
  }
`
