import camelCase from "lodash/camelCase"
import isPlainObject from "lodash/isPlainObject"

export function deepCamelTransform(data) {
  if (Array.isArray(data)) return data.map(i => deepCamelTransform(i))
  if (!isPlainObject(data)) return data

  const result = {}

  for (const key in data) result[camelCase(key)] = deepCamelTransform(data[key])

  return result
}
