import styled from "styled-components"

const Paragraph = styled.p`
  text-align: center;
  color: ${props => props.theme.secondaryTextColor};
  margin: 0;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  ${props =>
    props.isError &&
    `
    color: ${props.theme.inputErrorColor};
  `}
`

export default Paragraph
