import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "config/rollbar"

import React from "react"
import { createRoot } from "react-dom/client"
import App from "components/App"

const root = createRoot(document.getElementById("root"))
root.render(<App />)
