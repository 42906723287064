import { useState, useEffect } from "react"
import qs from "qs"
import { Button, Link, SuccessIcon } from "@compeon-os/components"
import styled from "styled-components"

import { client } from "modules/CompeonApi"
import { rotateIn, flyIn } from "styles/animations"
import Paragraph from "components/Paragraph"
import Spinner from "components/Spinner"

const STATES = {
  initial: "initial",
  verifying: "verifying",
  verificationFailed: "verificationFailed",
  rerequestable: "rerequestable",
  rerequested: "rerequested",
  tokenMissing: "tokenMissing",
}

const Centered = styled.div`
  text-align: center;
`

const StyledSuccessIcon = styled(SuccessIcon)`
  path:first-child {
    animation: ${rotateIn} 0.4s ease-out both;
    animation-delay: 330ms;
    transform-origin: center center;
  }

  path:last-child {
    animation: ${flyIn} 310ms cubic-bezier(0.45, 0.81, 0.85, 1.23) both;
    animation-delay: 745ms;
    fill: ${props => props.theme.secondaryColor};
  }
`

const StyledButton = styled(Button)`
  margin-top: 1rem;
`

const IsVerifying = () => (
  <Centered>
    <Spinner isLoading />
    <Paragraph>
      Die Verifizerung Ihrer E-Mail-Adresse läuft. Dies kann einen Augenblick
      dauern.
    </Paragraph>
  </Centered>
)

const TokenMissing = () => (
  <Paragraph isError>Sieht so aus, als wäre etwas schiefgelaufen.</Paragraph>
)

const EmailVerificationByToken = () => {
  const [appState, setAppState] = useState(STATES.initial)

  const [verificationToken, setVerificationToken] = useState("")
  const [verifiedEmail, setVerifiedEmail] = useState("")
  const [redirectUri, setRedirectUri] = useState("")

  useEffect(function verifyToken() {
    const { token } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    setVerificationToken(token)
    setAppState(STATES.verifying)

    if (!token) return setAppState(STATES.tokenMissing)

    client.Email.verify({ data: { verificationToken: token } })
      .then(response => {
        const { email, redirectUri: receivedRedirectUri } =
          response.data.data.attributes

        setVerifiedEmail(email)

        if (receivedRedirectUri) {
          setRedirectUri(receivedRedirectUri)

          setTimeout(() => window.location.replace(receivedRedirectUri), 2000)
        }
      })
      .catch(error => {
        if (error?.response?.status === 410) {
          setAppState(STATES.rerequestable)
        } else {
          setAppState(STATES.verificationFailed)
        }
      })
  }, [])

  const rerequestVerification = () => {
    setVerifiedEmail(null)

    client.Email.requestVerification({
      data: {
        verificationToken,
      },
    }).then(() => {
      setAppState(STATES.rerequested)
    })
  }

  return (
    <>
      {appState === STATES.verifying && !verifiedEmail && <IsVerifying />}

      {verifiedEmail && (
        <>
          <StyledSuccessIcon size={40} />
          <Paragraph>
            Die Bestätigung Ihrer E-Mail-Adresse <b>{verifiedEmail}</b> war
            erfolgreich.
          </Paragraph>
          {redirectUri && (
            <Paragraph>
              Sie werden automatisch weitergeleitet.
              <br />
              Sollten Sie nicht weitergeleitet werden, klicken Sie bitte{" "}
              <Link href={redirectUri}>hier</Link>.
            </Paragraph>
          )}
          {!redirectUri && (
            <Paragraph>Sie können diesen Tab jetzt schließen.</Paragraph>
          )}
        </>
      )}

      {(appState === STATES.verificationFailed ||
        appState === STATES.rerequestable) && (
        <>
          <Paragraph isError>
            Ihre E-Mail-Adresse konnte leider nicht bestätigt werden.
            <br />
            Bitte fordern Sie eine neue Bestätigung an.
          </Paragraph>
          {appState === STATES.rerequestable && (
            <StyledButton onClick={rerequestVerification}>
              Erneut anfordern.
            </StyledButton>
          )}
        </>
      )}

      {appState === STATES.rerequested && (
        <Paragraph>
          Erneute Bestätigung angefordert.
          <br />
          Sie können diesen Tab jetzt schließen.
        </Paragraph>
      )}

      {appState === STATES.tokenMissing && <TokenMissing />}
    </>
  )
}

export default EmailVerificationByToken
