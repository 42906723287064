import styled, { createGlobalStyle, ThemeProvider } from "styled-components"
import { defaultTheme, Footer, H1, Header, Paper } from "@compeon-os/components"

import EmailVerificationByToken from "components/EmailVerificationByToken"
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary"

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: ${props => props.theme.primaryFont}, -apple-system,
      BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${props => props.theme.lightBackgroundColor};
  }

  * {
    box-sizing: border-box;
  }
`

const Layout = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`

const MainContent = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledH1 = styled(H1)`
  font-size: 1.5rem;
  text-align: center;
  margin-top: 0;

  @media (min-width: ${props => props.theme.breakpointTablet}) {
    font-size: 2rem;
  }
`

const StyledPaper = styled(Paper)`
  padding: 1rem;
  max-width: 640px;
  min-height: 300px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      <Layout>
        <Header hideUserInformation />
        <MainContent>
          <ErrorBoundary>
            <StyledH1>Bestätigung Ihrer E-Mail-Adresse</StyledH1>
            <StyledPaper>
              <EmailVerificationByToken />
            </StyledPaper>
          </ErrorBoundary>
        </MainContent>
        <Footer />
      </Layout>
    </ThemeProvider>
  )
}

export default App
