import { Spinner } from "@compeon-os/components"
import styled from "styled-components"

const StyledSpinner = styled(Spinner)`
  width: 40px;
  height: 40px;
  animation-duration: 470ms;
`

export default StyledSpinner
