import { Component } from "react"
import Rollbar from "rollbar"
import styled from "styled-components"
import { node } from "prop-types"

import { env } from "utils/env"

const ErrorContent = styled.h1`
  margin: 36vh auto;
  text-align: center;
`

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  static propTypes = {
    children: node.isRequired,
  }

  componentDidCatch(error, errorInfo) {
    this.errorHandler(error, errorInfo)
  }

  get errorHandler() {
    if (env.IS_PRODUCTION_LIKE) return Rollbar.critical
    if (env.IS_TEST) return () => {}

    return console.error
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <ErrorContent>
            Sieht so aus, als wäre etwas schief gelaufen.
          </ErrorContent>
        </>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
